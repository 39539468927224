/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    div: "div",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "El proceso de instalación oficial de Raspberry Pi siempre me ha tirado para atrás por el hecho de necesitar teclado y pantalla, esto es un sistema empotrado… ¡Hagámoslo desde nuestra red!"), "\n", React.createElement(_components.p, null, "Primero que nada, vamos a ", React.createElement(_components.a, {
    href: "https://www.raspberrypi.org/downloads/raspbian/"
  }, "descargar la imagen"), " de Raspbian para instalarla en nuestra tarjeta SD, no vamos a usar entorno gráfico, así que he optado por Rasbian Jessie Lite, que tampoco se puede decir que es tan lite, la imagen pesa 1GB aproximadamente."), "\n", React.createElement(_components.p, null, "Descomprimimos lo que hemos bajado y seguimos el ", React.createElement(_components.a, {
    href: "https://www.raspberrypi.org/documentation/installation/installing-images/linux.md"
  }, "procedimiento"), " para quemar la imagen en la SD. Este procedimiento se reduce a estos dos comandos:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token function\">dd</span> <span class=\"token assign-left variable\">bs</span><span class=\"token operator\">=</span>4M <span class=\"token assign-left variable\">if</span><span class=\"token operator\">=</span><span class=\"token number\">2016</span>-05-27-raspbian-jessie.img <span class=\"token assign-left variable\">of</span><span class=\"token operator\">=</span>/dev/mmcblk0\n<span class=\"token function\">sync</span></code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Donde el dispositivo de salida ", React.createElement(_components.em, null, "/dev/mmcblk0"), " corresponde con el dispositivo donde se encuentra la tarjeta SD y ", React.createElement(_components.em, null, "2016-05-27-raspbian-jessie.img"), " es la imagen que hemos descomprimido."), "\n", React.createElement(_components.p, null, "Una vez terminado si queremos conectar la Raspberry a través de WiFi, tenemos que meter el SSID y la clave de nuestra red. He seguido ", React.createElement(_components.a, {
    href: "https://thepihut.com/blogs/raspberry-pi-tutorials/16018016-how-to-setup-wifi-on-the-raspberry-pi-raspbian"
  }, "éste tutorial"), " que explica como hacerlo editando el archivo ", React.createElement(_components.em, null, "/etc/wpa_supplicant/wpa_supplicant.conf"), " que podemos encontrar en las nuevas particiones de nuestra tarjeta SD. Si queremos conectar a la red nuestro cacharro directamente por Ethernet, no es necesario nada de esto, con un cable Ethernet y listo. Otro truco es conectar directamente al PC con un cable Ethernet, se creará una red local entre el PC y la Raspberry y también nos podremos conectar."), "\n", React.createElement(_components.p, null, "Por último conectamos la tarjeta SD y a la red de alguna de las formas que he comentado anteriormente. Ahora es cuando nos vamos a conectar por SSH, pero ¿Que dirección utilizamos? La magia llega de manos del demonio Avahi, que corre en las distribuciones Debian por defecto, esto hace una autoconfiguración de la red y nos da un punto de acceso dentro de nuestra red interna, esto es gracias a ", React.createElement(_components.a, {
    href: "http://www.multicastdns.org/"
  }, "mDNS"), ", un sistema DNS distribuido que permite que en una red local todos sepan los nombres de dominio del resto, así que simplemente nos conectaremos de esta forma:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token function\">ssh</span> pi@raspberrypi.local</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Como sabéis os pedirá el password, que por ahora estará por defecto a ", React.createElement(_components.em, null, "raspberry"), " y podéis empezar con vuestra Raspberry Pi a configurarlo todo a vuestro gusto, empezando por cambiar esta contraseña."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
